import assert from "assert"
import { DateType, Item, ItemId, ItemType } from "./effector"
import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

if (!supabaseUrl) alert("The REACT_APP_SUPABASE_URL environment variable isn't set.")
if (!supabaseAnonKey) alert("The REACT_APP_SUPABASE_ANON_KEY environment variable isn't set.")
assert.ok(supabaseUrl)
assert.ok(supabaseAnonKey)

export const supabase = createClient(supabaseUrl, supabaseAnonKey)


type TableItem = {
    id: ItemId
    data: Record<string, string>
    item_type: ItemType
    updated_at: DateType
    user_id: string
}


export async function supabaseSelectItems() {
    const { error, data } = await supabase
        .from<TableItem>("items")
        .select(`
            id,
            data,
            item_type,
            updated_at
        `)

    if (error) {
        throw Error("Fetch network request failed.")
    }

    assert.ok(data)
    console.log((data));
    
    return data
}

export async function supabaseInsertItem(itemType: ItemType) {
    const { error, data } = await supabase
        .from<TableItem>('items')
        .insert([
            {
                data: {
                    title: '',
                    description: '',
                },
                item_type: itemType,
                user_id: supabase.auth.user()?.id,
            }
        ])

    if (error) {
        throw Error("Item update network request failed.")
    }

    assert.ok(data)

    return data
}

export async function supabaseDeleteItem(itemId: ItemId) {
    const { error, data } = await supabase
        .from<TableItem>('items')
        .delete()
        .match({ id: itemId })

    if (error) {
        throw Error("Item update network request failed.")
    }

    assert.ok(data)

    return data
}

export async function supabaseUpdateItem(item: Item) {
    return await supabase
        .from('items')
        .update({ data: item.data })
        .match({ id: item.id })
}



type TableLink = {
    item_id: ItemId
    linked_item_id: ItemId
}

export async function supabaseSelectLinks() {
    const { error, data } = await supabase
        .from<TableLink>("items_links")
        .select(`
            item_id,
            linked_item_id
        `)

    if (error) {
        throw Error("Fetch network request failed.")
    }

    assert.ok(data)

    return data
}

export async function supabaseInsertLink(tableLink: TableLink) {
    const response = await supabase
        .from<TableLink>('items_links')
        .insert([tableLink])

    if (response.error) {
        if (response.status === 409 /* Conflict */) {
            // TODO: better handle this situation, 
            //eg. filter out existing links from the select page
            console.log("The link already exists.")
            return null
        } else {
            console.error(response);
            throw Error("Link insert network request failed.")
        }
    }

    assert.ok(response.data)

    return response.data
}


export async function supabaseDeleteLink(tableLink: TableLink) {
    const { error, data } = await supabase
        .from<TableLink>('items')
        .delete()
        .match(tableLink)

    if (error) {
        throw Error("Link delete network request failed.")
    }

    assert.ok(data)

    return data
}