import create from 'zustand'
import produce from "immer"
import { assertExists } from './utils/typescript-helpers'
import { useCallback } from 'react'
import { Block, BlockId } from './Blocks'

type Store = Readonly<{
    "blocks": Map<number, Block>
    "insertBlock": (block: Block    ) => void
    "deleteBlock": (block: Block) => void
    "updateBlock": (block: Block) => void
}>

export const useStore = create<Store>((set) => ({
    blocks: new Map([]),
    insertBlock: (block) => set((store) => produce(store, draft => {
        draft.blocks.set(block.id, block)
    })),
    deleteBlock: (block) => set((store) => produce(store, draft => {
        draft.blocks.delete(block.id)
    })),
    updateBlock: (block) => set((store) => produce(store, draft => {
        const newBlock = { ...block, updatedAt: new Date().getTime() }
        draft.blocks.set(block.id, newBlock)
    })),
}))

type ReducerNames = "insertBlock" | "deleteBlock" | "updateBlock"

export function useReducer(reducerName: ReducerNames) {
    return useStore(useCallback(store => store[reducerName], [reducerName]))
}

export function useBlocks() {
    return useStore(useCallback(store => store.blocks, []))
}

export function useBlock(blockId: BlockId) {
    return assertExists(
        useStore(useCallback(store => store.blocks.get(blockId), [blockId]))
    )
}




