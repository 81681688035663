import { PostgrestResponse } from '@supabase/postgrest-js'
import assert from 'assert'
import { createEffect, createEvent, createStore } from 'effector'
import { useStore, useStoreMap } from 'effector-react'
import produce, { enableMapSet } from 'immer'
import { supabaseDeleteItem, supabaseDeleteLink, supabaseInsertItem, supabaseInsertLink, supabaseSelectItems, supabaseSelectLinks, supabaseUpdateItem } from './supabase'
import { useSync } from './useSync'
import { bugMessage } from './utils/typescript-helpers'

enableMapSet()

export const createItem = createEvent<ItemType>()
export const updateItem = createEvent<Item>()
export const deleteItem = createEvent<ItemId>()
// export const createLink = createEvent<{ fromItemId: ItemId, toItemId: ItemId }>()
// export const deleteLink = createEvent<{ fromItemId: ItemId, toItemId: ItemId }>()

const storeSetItem = createEvent<Item>()
const storeDeleteItem = createEvent<ItemId>()
// const storeSetLink = createEvent<{ fromItemId: ItemId, toItemId: ItemId }>()
// const storeDeleteLink = createEvent<{ fromItemId: ItemId, toItemId: ItemId }>()

const navigate = createEvent<string | number>()
const dataArrived = createEvent<void>()

const $items = createStore<Map<ItemId, Item>>(new Map())
export const $lastRedirect = createStore<string | number | null>(null)
export const $dataAvailable = createStore<boolean>(false)

const dbSelectItems = createEffect(supabaseSelectItems)
const dbInsertItem = createEffect(supabaseInsertItem)
const dbDeleteItem = createEffect(supabaseDeleteItem)
// const dbSelectLinks = createEffect(supabaseSelectLinks)
// const dbInsertLink = createEffect(supabaseInsertLink)
// const dbDeleteLink = createEffect(supabaseDeleteLink)

dbSelectItems()


updateItem.map(item => ({ ...item, updatedAt: new Date().getTime() }))
    .watch(storeSetItem)

deleteItem
    .watch(dbDeleteItem)

// createLink
//     .watch(({ fromItemId, toItemId }) => {
//         dbInsertLink({
//             item_id: fromItemId,
//             linked_item_id: toItemId,
//         })
//     })


dbSelectItems
    .done.watch(({ result: tableItems }) => {
        tableItems.forEach(tableItem => {
            storeSetItem({
                id: tableItem.id,
                itemType: tableItem.item_type,
                data: tableItem.data,
                updatedAt: Date.parse(tableItem.updated_at),
                links: new Set(),
            })
        })
        dataArrived()
        // dbSelectLinks()
    })

// dbSelectLinks
//     .done.watch(({ result: tableLinks }) => {
//         tableLinks.forEach(({ item_id, linked_item_id }) => {
//             storeSetLink({
//                 fromItemId: item_id,
//                 toItemId: linked_item_id,
//             })
//         })
//     })

dbInsertItem
    .done.watch(({ result: tableItems }) => {
        tableItems.forEach(tableItem => {
            storeSetItem({
                id: tableItem.id,
                itemType: tableItem.item_type,
                data: tableItem.data,
                updatedAt: Date.parse(tableItem.updated_at),
                links: new Set(),
            })
        })
        navigate(`/${pluralize(tableItems[0].item_type)}/${tableItems[0].id}`)
    })

// dbInsertLink
//     .done.watch(({ result: tableItems }) => {
//         if (tableItems) {
//             tableItems.forEach(({ item_id, linked_item_id }) => {
//                 storeSetLink({
//                     fromItemId: item_id,
//                     toItemId: linked_item_id,
//                 })
//             })
//         }
//         navigate(-1)
//     })

dbDeleteItem
    .done.watch(({ params }) => {
        navigate(-1)
        storeDeleteItem(params)
    })

// dbDeleteLink
//     .done.watch(({ result: tableLinks }) => {
//         if (tableLinks) {
//             tableLinks.forEach(({ item_id, linked_item_id }) => {
//                 storeDeleteLink({
//                     fromItemId: item_id,
//                     toItemId: linked_item_id,
//                 })
//             })
//         }
//     })

createItem
    .watch(dbInsertItem)

$lastRedirect
    .on(navigate, (_, location) => location)

$dataAvailable
    .on(dataArrived, (_) => true)

$items
    .on(storeSetItem, (items, item) => produce(items, draft => {
        draft.set(item.id, item)
    }))
    .on(storeDeleteItem, (items, itemId) => produce(items, draft => {
        draft.delete(itemId)
    }))
// .on(storeSetLink, (items, { fromItemId, toItemId }) => produce(items, draft => {
//     draft.set(fromItemId, produce(items.get(fromItemId) as Item, draft => {
//         draft.links.add(toItemId)
//     }))
// }))
// .on(storeDeleteLink, (items, { fromItemId, toItemId }) => produce(items, draft => {
//     draft.set(fromItemId, produce(items.get(fromItemId) as Item, draft => {
//         draft.links.delete(toItemId)
//     }))
// }))

const $itemsList = $items.map(items => Array.from(items.values()))





export function useItems(itemIdSet?: Set<ItemId>) {
    const items = useStore($itemsList)

    if (!itemIdSet) {
        return items
    }

    return items.filter(item => itemIdSet.has(item.id))
}

export function useItem(itemId: ItemId) {
    const item = useStoreMap({
        store: $items,
        keys: [itemId],
        fn: (items, [itemId]) => items.get(itemId)
    })

    assert.ok(item)

    return item
}




type UseSyncccccccc = {
    value: any
    item: Item
}

export function useSynccccc({ value, item }: UseSyncccccccc) {
    return useSync(async () => {
        const data = supabaseUnwrapData(await supabaseUpdateItem(item))

        if (!data) {
            return "error"
        }

        return "success"
    }, {}, [value])
}

function supabaseUnwrapData<T>(supabaseResult: PostgrestResponse<T>) {
    const { error, data } = supabaseResult

    if (error) {
        console.error(error)
        alert("An error occured while interacting with the database. For more details, please check the console log.")
        return null
    }

    if (!data) {
        throw Error(bugMessage("There is no data returned by the database request."))
    }

    return data
}




export const itemTypesTable: [string, string][] = [
    ["action", "actions"],
    ["project", "projects"],
    ["note", "notes"],
    ["topic", "topics"],
    ["activity", "activities"],
]

export const itemTypesTableeeee: { singular: string, plural: string, determiner: string }[] = [
    {
        singular: "action",
        plural: "actions",
        determiner: "an",
    },
    {
        singular: "project",
        plural: "projects",
        determiner: "a",
    },
    {
        singular: "note",
        plural: "notes",
        determiner: "a",
    },
    {
        singular: "topic",
        plural: "topics",
        determiner: "a",
    },
    {
        singular: "activity",
        plural: "activities",
        determiner: "an",
    },
]

const itemTypesMap = new Map(itemTypesTable)

const itemTypesTableFlipped = itemTypesTable
    .map(([singular, plural]) => [plural, singular] as [string, string])

const itemTypesFlippedMap = new Map(itemTypesTableFlipped)

export function pluralize(itemType: ItemType) {
    const plural = itemTypesMap.get(itemType)

    assert.ok(plural)

    return plural
}

export function singularize(itemTypePlural: string) {
    const singular = itemTypesFlippedMap.get(itemTypePlural)

    assert.ok(singular)

    return singular
}

export function determinerOf(itemType: ItemType) {
    const singular = itemTypesTableeeee.find(x => x.singular === itemType)

    assert.ok(singular)

    return singular.determiner
}



export type ItemType = string
export type ItemId = number
export type IconType = string
export type TextType = string
export type DateType = string

export type Item = {
    id: ItemId
    data: Record<string, string>
    itemType: ItemType
    updatedAt: number
    links: Set<ItemId>
}



export type Link = {
    fromItemId: ItemId
    toItemId: ItemId
}

export type WithItem = { item: Item }
export type WithItemType = { itemType: ItemType }
export type WithItemId = { itemId: ItemId }
