// -- Element type

import assert from "assert";
import { HTMLAttributes } from "react";

// -- Element Type

export type ElementType = HTMLAttributes<HTMLElement>
export type HtmlElementType = HTMLAttributes<HTMLElement>

export type UseState<T> = [T, React.Dispatch<React.SetStateAction<T>>]

export type ClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>
export type ChangeEvent = React.ChangeEvent<HTMLInputElement>

// -- Object.entries()

type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

export function entries<T>(obj: T): Entries<T> {
  return Object.entries(obj) as any;
}


export const mapMap = <K, V>(mapFn: (v: V, i: number) => unknown, map: Map<K, V>) => {
  return Array.from(map.values(), mapFn)
}

export const arrayFrom = <K, V>(map: Map<K, V>) => {
  return Array.from(map.values())
}

export type WithChildren = { children: React.ReactNode }
export type WithClass = { className?: string }
export type WithOnClick<T = HTMLButtonElement> = { onClick: (e: React.MouseEvent<T, MouseEvent>) => void }

export function bugMessage(message: string) {
  return `${message} This shouldn't happen, there must be a bug somewhere.`
}

export function dbg<T>(value: T) {
  console.log(value)
  return value
}

export function assertExists<T>(value: T) {
  assert.ok(value)
  return value as NonNullable<T>
}