import React, { useRef } from 'react';
import './App.css';
import { assertExists, dbg, WithChildren, WithClass } from './utils/typescript-helpers';
import ContentEditable from 'react-contenteditable'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { Account, AuthGate } from './Authentication';
import assert from 'assert';
import { $dataAvailable, createItem, deleteItem, determinerOf, ItemId, pluralize, singularize, updateItem, useItem, useItems, useSynccccc, WithItem, WithItemId, WithItemType } from './effector';
import { useStore } from 'effector-react';
import { Block } from './Blocks';
import { useBlock } from './zustand';

export default function App() {
  return (
    <div className="y">
      <div className="max-w-2xl w-full py-4 self-center">
        <AuthGate>
          <DataGate>
            <Routes>
              <Route index element={<HomePage />} />
              <Route path=":itemTypePlural" element={<ItemListPage />} />
              <Route path=":itemTypePlural/select" element={<ItemSelectPage />} />
              <Route path=":itemTypePlural/:itemId" element={<ItemPage />} />
              <Route path=":itemTypePlural/select" element={<ItemSelectPage />} />
              <Route path="account" element={<Account />} />
              <Route path="page/:pageId" element={<BlockPage />} />
              <Route path="*" element={<UrlNotFound />} />
            </Routes>
          </DataGate>
        </AuthGate>
      </div>
    </div>
  )
}

function DataGate({ children }: WithChildren) {
  const dataAvailable = useStore($dataAvailable)

  if (!dataAvailable) {
    return null
  }

  return <>{children}</>
}

/*
function HomePage() {
  return (
    <div className="y space-y-6 xs:space-y-8 sm:space-y-12 py-4 xs:py-8 sm:py-12">
      <div className="px-4 x space-x-2">
        <h1 className="text-2xl font-bold outline-none capitalize flex-grow">
          management
        </h1>
      </div>
      <div className="y divide-y divide-gray-300 font-semibold">
        {
          itemTypesTable.map(([itemType, itemTypePlural]) => (
            <Link
              className="px-4 py-2 capitalize"
              to={`/${itemTypePlural}`}
              key={itemType}
            >
              {itemTypePlural}
            </Link>
          ))
        }
      </div>
    </div>
  )
}
*/

function HomePage() {
  const items = useItems()
  const now = new Date().getTime()
  return (
    <div className="y space-y-6 xs:space-y-8 sm:space-y-12 py-4 xs:py-8 sm:py-12">
      <div className="px-4 x space-x-2">
        <h1 className="text-2xl font-bold outline-none capitalize flex-grow">
          management
        </h1>
        <CreateItemButton itemType={"project"} />
      </div>
      <div className="y divide-y divide-gray-200">
        {items
          .sort((a, b) => b.updatedAt - a.updatedAt)
          .map((item) => (
            <div className="px-4" key={item.id}>
              <Link to={`/${pluralize(item.itemType)}/${item.id}`}>
                <ListView itemId={item.id} />
              </Link>
            </div>
          ))}
      </div>
    </div>
  )
}

function BlockPage() {
  const blockId = useParamBlockId()

  return (
    <div className="container space-y-2">
      {/* <div className="px-4 x space-x-2">
        <DeleteItemButton item={block} />
      </div> */}
      <Block blockId={blockId} />
    </div>
  )
}

function ItemListPage() {
  const itemType = useParamItemType()
  const items = useItems()

  return (
    <div className="y space-y-6 xs:space-y-8 sm:space-y-12 py-4 xs:py-8 sm:py-12">
      <div className="px-4 x space-x-2 text-sm">
        <BackButton />
        <h1 className="text-2xl font-bold outline-none capitalize flex-grow">
          {pluralize(itemType)}
        </h1>
        <CreateItemButton itemType={itemType} />
      </div>
      <div className="y divide-y divide-gray-200">
        {items
          .filter(item => item.itemType === itemType)
          .map((item) => (
            <div className="px-4" key={item.id}>
              <Link to={`/${pluralize(itemType)}/${item.id}`}>
                <ListView itemId={item.id} />
              </Link>
            </div>
          ))}
      </div>
    </div>
  )
}

function ListView({ itemId }: WithItemId) {
  const item = useItem(itemId)

  return (
    <div className="y py-2 pt-3 text-left">
      {
        item.data.title !== ''
          ? <strong className="font-semibold text-lg">
            {item.data.title}
          </strong>
          : null
      }
      {
        item.data.description !== ''
          ? <p className="text-sm text-gray-700 line-clamp-2">
            {item.data.description}
          </p>
          : null
      }
      {
        item.data.title === '' && item.data.description === ''
          ? <p className="text-sm text-gray-500 italic">
            Empty {item.itemType}
          </p>
          : null
      }
    </div>
  )
}

function ItemPage() {
  const itemId = useParamItemId()
  const item = useItem(itemId)

  return (
    <div className="y space-y-6 xs:space-y-8 sm:space-y-12 py-4 xs:py-8 sm:py-12" key={item.id}>
      <div className="y space-y-2">
        <div className="px-4 x space-x-2">
          <BackButton />
          <h1 className="text-xl font-bold outline-none flex-grow">
            <EditableText placeholder="Give it a title" dataKey="title" itemId={item.id} />
          </h1>
          <DeleteItemButton item={item} />
        </div>
        <div className="px-4 text-sm text-gray-400">
          {item.data.activity}
        </div>
        <div className="px-4 text-gray-700 outline-none">
          <EditableText placeholder="Write some description about it..." dataKey="description" itemId={item.id} />
        </div>
      </div>
      <LinkedItems itemType={"note"} />
      <LinkedItems itemType={"action"} />
      <LinkedItems itemType={"project"} />
      <LinkedItems itemType={"topic"} />
      {/* <LinkedTopics /> */}
    </div>
  )
}


function ItemSelectPage() {
  const itemType = useParamItemType()
  const items = useItems()
  const nextEvent = useNextEvent()

  return (
    <div className="y space-y-6 xs:space-y-8 sm:space-y-12 py-4 xs:py-8 sm:py-12">
      <div className="px-4 x justify-between">
        <h1 className="text-2xl font-bold outline-none">
          Choose {`${determinerOf(itemType)} ${itemType}`}
        </h1>
        <CreateItemButton itemType={itemType} />
      </div>
      <div className="y divide-y divide-gray-200">
        {
          items
            .filter(item => item.itemType === itemType)
            .map(item => (
              <button onClick={() => nextEvent(item.id)} key={item.id}>
                <ListView itemId={item.id} />
              </button>
            ))
        }
      </div>
    </div>
  )
}






function LinkedItems({ itemType }: WithItemType) {
  const itemId = useParamItemId()
  const item = useItem(itemId)
  const linkedItems = useItems(item.links)

  return (
    <div className="y">
      <div className="x space-x-4 justify-between w-full px-4 text-xs">
        <SectionTitle>{pluralize(itemType)}</SectionTitle>
        <AddLinkButton itemType={itemType} />
      </div>
      <div className="y divide-y divide-gray-200">
        {
          linkedItems
            .filter(item => item.itemType === itemType)
            .map(item => (
              <div className="px-4 x space-x-4">
                <Link className="flex-grow" to={`/${pluralize(itemType)}/${item.id}`} key={item.id}>
                  <ListView itemId={item.id} />
                </Link>
                <DeleteItemButton className="" item={item} />
              </div>
            ))
        }
      </div>
    </div>
  )
}

// function LinkedTopics() {
//   const itemId = useParamItemId()
//   const item = useItem(itemId)
//   const linkedItems = useItems(item.links)

//   return (
//     <div className="y space-y-4">
//       <div className="x space-x-4 justify-between w-full px-4 text-xs">
//         <SectionTitle>Related Topics and Areas</SectionTitle>
//         <AddLinkButton itemType={"topic"} />
//       </div>
//       <div className="x px-4 flex-wrap">
//         {
//           linkedItems
//             .filter(item => item.itemType === "topic")
//             .map(item => (
//               <TopicChip itemId={item.id} />
//             ))
//         }
//       </div>
//     </div>
//   )
// }

// function TopicChip({ itemId }: WithItemId) {
//   const item = useItem(itemId)

//   return (
//     <div className="y px-2 py-1 mr-2 mb-2 bg-gray-100 rounded self-start">
//       <p className="text-sm">
//         {item.data.title}
//       </p>
//     </div>
//   )
// }

function AddLinkButton({ itemType }: WithItemType) {
  const itemId = useParamItemId()
  return (
    <Link
      className="uppercase text-blue-600 font-semibold py-1 px-2 flex-shrink-0"
      style={{ marginRight: "-0.5rem" }}
      to={`/${pluralize(itemType)}/select?linkfrom=${itemId}`}
    >
      + {itemType}
    </Link>
  )
}

function CreateItemButton({ itemType }: WithItemType) {
  return (
    <button
      className="uppercase text-blue-600 font-semibold py-1 px-2 flex-shrink-0"
      style={{ marginRight: "-0.5rem" }}
      onClick={() => createItem(itemType)}
    >
      + new
    </button>
  )
}

function SectionTitle({ children }: WithChildren) {
  return (
    <h2 className="uppercase font-semibold text-gray-400 py-1">
      {children}
    </h2>
  )
}

function DeleteItemButton({ item, className }: WithItem & WithClass) {
  return (
    <button
      className={`flex-shrink-0 text-gray-700 ${className}`}
      style={{ marginRight: '-0.5rem' }}
      onClick={() => deleteItem(item.id)}
    >
      <svg className="h-5 w-5 xs:h-6 xs:w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
      </svg>
    </button>
  )
}

function BackButton() {
  const navigate = useNavigate()
  return (
    <button
      className={`flex-shrink-0 text-gray-700`}
      style={{ marginLeft: '-0.5rem' }}
      onClick={() => navigate(-1)}
    >
      <svg className="h-5 w-5 xs:h-6 xs:w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
      </svg>
    </button>
  )
}





function UrlNotFound() {
  const location = useLocation()
  return <>
    <p className="text-center font-bold">404: Not Found</p>
    <p className="text-center">There's nothing on <code>{location.pathname}</code></p>
  </>
}





type EditableText_ = {
  itemId: ItemId
  dataKey: string
  placeholder: string
}

function EditableText({ itemId, dataKey, placeholder }: EditableText_) {
  const item = useItem(itemId)
  const text = useRef(item.data[dataKey]);

  const handleChange = (evt: any) => {
    text.current = evt.target.value;
    const newItem = {
      ...item,
      ...{
        data: {
          ...item.data,
          [dataKey]: text.current
        },
      }
    }
    updateItem(newItem)
  };

  const handleBlur = () => {
    const newItem = {
      ...item,
      ...{
        data: {
          ...item.data,
          [dataKey]: text.current
        },
      }
    }
    updateItem(newItem)
  };

  const state = useSynccccc({ value: item.data[dataKey], item })

  return (
    <div className="relative">
      <div className="text-gray-300 absolute top-0 left-0 right-0 bottom-0">
        <span className="absolute select-none">{!text.current && placeholder}</span>
        {
          state === "sending" || state === "failed" ? (
            <svg className="w-[0.75em] h-[0.75em] absolute top-0 right-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 3.11 16.44 3.11 16.44M3.11 16.44H7.63M3.11 16.44V21.44M2 12C2 6.48 6.44 2 12 2C18.67 2 22 7.56 22 7.56M22 7.56V2.56M22 7.56H17.56" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          ) : null
        }
      </div>
      <ContentEditable
        className="outline-none relative"
        html={text.current}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </div>
  )
}





// TODO: delete "linkfrom" from search params when the createLink event is fired
export function useNextEvent() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const linkFrom = searchParams.get("linkfrom")
  if (linkFrom) {
    const fromItemId = parseInt(linkFrom)
    assert(!isNaN(fromItemId))
    return function (toItemId: ItemId) {
      // createLink({
      //   fromItemId,
      //   toItemId
      // })
      // navigate(-1)
    }
  }

  throw Error()
}





function useParamItemType() {
  const value = assertExists(useParams()["itemTypePlural"])
  return singularize(value)
}

function useParamItemId() {
  const value = assertExists(useParams()["itemId"])
  const numberValue = parseInt(value)

  assert.ok(!isNaN(numberValue))

  return numberValue
}


function useParamBlockId() {
  const value = assertExists(useParams()["blockId"])
  const numberValue = parseInt(value)

  assert.ok(!isNaN(numberValue))

  return numberValue
}

