import { Session } from '@supabase/gotrue-js';
import assert from 'assert';
import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { supabase } from './supabase';
import { WithChildren } from './utils/typescript-helpers';

const SessionContext = React.createContext<Session | null>(null)


export function AuthGate({ children }: WithChildren) {
  const [session, setSession] = useState<Session | null>(null)

  useEffect(() => {
    setSession(supabase.auth.session())

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])

  return (
    !session
      ? <AuthWithCredentials />
      : <SessionContext.Provider value={session}>
        {children}
      </SessionContext.Provider>
  )
}

function AuthWithCredentials() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [searchParams] = useSearchParams()

  const handleLogin = async (email: string, password: string) => {
    try {
      setLoading(true)
      const { error } = await supabase.auth.signIn({ email, password })
      if (error) throw error
    } catch (error: any) {
      alert(error.error_description || error.message)
    } finally {
      setLoading(false)
    }
  }

  const handlePasswordResetRequest = async (email: string) => {
    try {
      setLoading(true)
      const { data, error } = await supabase.auth.api.resetPasswordForEmail(email)
      if (error) throw error
      alert('Check your email for the password reset link!')
    } catch (error: any) {
      alert(error.error_description || error.message)
    } finally {
      setLoading(false)
    }
  }

  const handlePasswordRecovery = async () => {
    const accessToken = searchParams.get("access_token")
    assert.ok(accessToken)
    try {
      setLoading(true)
      const { error } = await supabase.auth.api.updateUser(accessToken, { password })
      if (error) throw error
      alert('Password successfully changed!')
    } catch (error: any) {
      alert(error.error_description || error.message)
    } finally {
      setLoading(false)
    }
  }

  if (searchParams.get("type") === "recovery") {
    return (
      <div className="y">
        <input
          className="inputField"
          type="password"
          placeholder="Your password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
                  <button
            onClick={(e) => {
              e.preventDefault()
              handlePasswordRecovery()
            }}
            className={'button block'}
            disabled={loading}
          >
            {loading ? <span>Loading</span> : <span>Reset Password</span>}
          </button>
      </div>
    )
  }

  return (
    <div className="row flex flex-center">
      <div className="col-6 form-widget">
        <h1 className="header">Flywheel - Management</h1>
        <p className="description">Sign in with your email and password below</p>
        <div>
          <input
            className="inputField"
            type="email"
            placeholder="Your email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="inputField"
            type="password"
            placeholder="Your password"
            autoComplete="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <button
            onClick={(e) => {
              e.preventDefault()
              handleLogin(email, password)
            }}
            className={'button block'}
            disabled={loading}
          >
            {loading ? <span>Loading</span> : <span>Sign in</span>}
          </button>
          <button
            onClick={(e) => {
              e.preventDefault()
              handlePasswordResetRequest(email)
            }}
            className={'button block'}
            disabled={loading}
          >
            {loading ? <span>Loading</span> : <span>Reset Password</span>}
          </button>
        </div>
      </div>
    </div>
  )
}


function AuthWithMagicLink() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const handleLogin = async (email: string) => {
    try {
      setLoading(true)
      const { error } = await supabase.auth.signIn({ email })
      if (error) throw error
      alert('Check your email for the login link!')
    } catch (error: any) {
      alert(error.error_description || error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="row flex flex-center">
      <div className="col-6 form-widget">
        <h1 className="header">Flywheel - Management</h1>
        <p className="description">Sign in via magic link with your email below</p>
        <div>
          <input
            className="inputField"
            type="email"
            placeholder="Your email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <button
            onClick={(e) => {
              e.preventDefault()
              handleLogin(email)
            }}
            className={'button block'}
            disabled={loading}
          >
            {loading ? <span>Loading</span> : <span>Send magic link</span>}
          </button>
        </div>
      </div>
    </div>
  )
}


export function UpdatePassword() {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')

  const handleLogin = async (password: string) => {
    try {
      setLoading(true)
      const { user, error } = await supabase.auth.update({ password })
      if (error) throw error
      alert('Password successfully updated!')
    } catch (error: any) {
      alert(error.error_description || error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="row flex flex-center">
      <div className="col-6 form-widget">
        <h1 className="header">Flywheel - Management</h1>
        <p className="description">Change your password below</p>
        <div>
          <input
            className="inputField"
            type="password"
            placeholder="Your new password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <button
            onClick={(e) => {
              e.preventDefault()
              handleLogin(password)
            }}
            className={'button block'}
            disabled={loading}
          >
            {loading ? <span>Loading</span> : <span>Change Password</span>}
          </button>
        </div>
      </div>
    </div>
  )
}

export function Account() {
  const session = useContext(SessionContext)
  if (!session) throw Error('The `session` value is not available outside of the authgate')
  const [loading, setLoading] = useState(true)
  const [username, setUsername] = useState<string | null>(null)
  const [website, setWebsite] = useState<string | null>(null)
  const [avatar_url, setAvatarUrl] = useState<string | null>(null)

  useEffect(() => {
    getProfile()
  }, [session])

  async function getProfile() {
    try {
      setLoading(true)
      const user = supabase.auth.user()

      if (!user) throw Error("`user` is null")

      let { data, error, status } = await supabase
        .from('profiles')
        .select(`username, website, avatar_url`)
        .eq('id', user.id)
        .single()

      if (error && status !== 406) {
        throw error
      }

      if (data) {
        setUsername(data.username)
        setWebsite(data.website)
        setAvatarUrl(data.avatar_url)
      }
    } catch (error: any) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function updateProfile({ username, website, avatar_url }: Profile) {
    try {
      setLoading(true)
      const user = supabase.auth.user()

      if (!user) throw Error("`user` is null")

      const updates = {
        id: user.id,
        username,
        website,
        avatar_url,
        updated_at: new Date().getTime(),
      }

      let { error } = await supabase.from('profiles').upsert(updates, {
        returning: 'minimal', // Don't return the value after inserting
      })

      if (error) {
        throw error
      }
    } catch (error: any) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  if (!session.user) throw Error("`session.user` is null")

  return (
    <div className="y space-y-8">
      <div className="form-widget">
        <div>
          <label htmlFor="email">Email</label>
          <input id="email" type="text" value={session.user.email} disabled />
        </div>
        <div>
          <label htmlFor="username">Name</label>
          <input
            id="username"
            type="text"
            value={username || ''}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="website">Website</label>
          <input
            id="website"
            type="website"
            value={website || ''}
            onChange={(e) => setWebsite(e.target.value)}
          />
        </div>
        <div>
          <button
            className="button block primary"
            onClick={() => updateProfile({ username, website, avatar_url })}
            disabled={loading}
          >
            {loading ? 'Loading ...' : 'Update'}
          </button>
        </div>
        <div>
          <button className="button block" onClick={() => supabase.auth.signOut()}>
            Sign Out
          </button>
        </div>
      </div>
      <UpdatePassword />
    </div>
  )
}

type Profile = {
  username: string | null
  website: string | null
  avatar_url: string | null
}