/* eslint-disable @typescript-eslint/no-redeclare */
import { useBlock } from "./zustand"



export function Block({ blockId }: WithBlockId) {
  const block = useBlock(blockId)

  switch (block.blockType) {
    case "h1":
      return <H1 block={block} />
    case "h2":
      return <H2 block={block} />
    case "h3":
      return <H3 block={block} />
    case "paragraph":
      return <Paragraph block={block} />
    case "checkbox":
      return <Checkbox block={block} />
    case "page":
      return <Page block={block} />
    case "bullet":
      return <Bullet block={block} />
  }
}



type H1 = BlockBase & {
  blockType: "h1"
  content: {
    text: TextType
  }
}

function H1({ block }: { block: H1 }) {
  return (
    <h1>
      {block.content.text}
    </h1>
  )
}



type H2 = BlockBase & {
  blockType: "h2"
  content: {
    text: TextType
  }
}

function H2({ block }: { block: H2 }) {
  return (
    <h2>
      {block.content.text}
    </h2>
  )
}



type H3 = BlockBase & {
  blockType: "h3"
  content: {
    text: TextType
  }
}

function H3({ block }: { block: H3 }) {
  return (
    <h3>
      {block.content.text}
    </h3>
  )
}



type Paragraph = BlockBase & {
  blockType: "paragraph"
  content: {
    text: TextType
  }
}

function Paragraph({ block }: { block: Paragraph }) {
  return (
    <p>
      {block.content.text}
    </p>
  )
}



type Bullet = BlockBase & {
  blockType: "bullet"
  content: {
    text: TextType
    color: string
  }
}

function Bullet({ block }: { block: Bullet }) {
  return (
    <li>
      {block.content.text}
    </li>
  )
}



type Checkbox = BlockBase & {
  blockType: "checkbox"
  content: {
    text: TextType
    isChecked: boolean
  }
}

function Checkbox({ block }: { block: Checkbox }) {
  return (
    <div className="x space-x-4">
      <input type="checkbox" checked={block.content.isChecked} />
      <p>
        {block.content.text}
      </p>
    </div>
  )
}



type Page = BlockBase & {
  blockType: "page"
  content: {
    body: BlockId[]
  }
}

function Page({ block }: { block: Page }) {
  return (
    <div className="y space-y-2">
      {
        block.content.body
          .map(blockId => (
            <Block blockId={blockId} key={blockId} />
          ))
      }
    </div>
  )
}



type BlockBase = {
  id: BlockId
  updatedAt: number
}

export type Block = H1 | H2 | H3 | Paragraph | Bullet | Checkbox | Page
export type BlockType = "h1" | "h2" | "h3" | "paragraph" | "bullet" | "checkbox" | "page"
export type BlockId = number

type IconType = string
type TextType = string

export type WithBlock = { block: Block }
export type WithBlockType = { blockType: BlockType }
export type WithBlockId = { blockId: BlockId }