import React, { useCallback, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import './reset.css';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, useNavigate } from "react-router-dom";
import { useStore } from 'effector-react';
import { $lastRedirect } from './effector';
// import { QueryClient, QueryClientProvider } from 'react-query';


// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       cacheTime: 60 * 60 * 1000,
//       staleTime: 10 * 60 * 1000,
//     }
//   }
// })



ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <NavigateHandler />
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

function NavigateHandler() {
  const lastRedirect = useStore($lastRedirect)
  const stableNavigate = useCallback(useNavigate(), [])

  useLayoutEffect(() => {
    if (typeof lastRedirect === "string") {
      stableNavigate(lastRedirect)
    }
    if (typeof lastRedirect === "number") {
      stableNavigate(lastRedirect)
    }
  }, [lastRedirect, stableNavigate])

  return null
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
