import { useEffect, useState } from "react"

type SyncingState = 'synced' | 'modified' | "sending" | "failed"

type TimeOptions = {
  intervalTime?: number
  delayTime?: number
  retryTime?: number
}

export function useSync(
  syncFn: () => Promise<"success" | "error">,
  { intervalTime = 10_000, delayTime = 2_000, retryTime = 2_000 }: TimeOptions,
  deps: React.DependencyList
) {
  // the state should be an object to loose referential equality when reentering state
  const [syncState, setSyncState] = useState<{ state: SyncingState }>({ state: 'synced' })

  // set state to modified on every data change
  useEffect(() => {
    return () => {
      setSyncState({ state: "modified" })
    }
  }, deps)

  // sync every now and then during continous editing
  useEffect(() => {
    if (syncState.state === "modified") {
      const handle = setTimeout(() => {
        setSyncState({ state: "sending" })
      }, intervalTime)

      return () => {
        clearTimeout(handle)
      }
    }
  }, [syncState.state])

  // sync after a certail time of inactivity
  useEffect(() => {
    if (syncState.state === "modified") {
      const handle = setTimeout(() => {
        setSyncState({ state: "sending" })
      }, delayTime)

      return () => {
        clearTimeout(handle)
      }
    }
  }, [syncState])

  // retry periodicalli after a faild sync
  useEffect(() => {
    if (syncState.state === "failed") {
      const handle = setTimeout(() => {
        setSyncState({ state: "sending" })
      }, retryTime)

      return () => {
        clearTimeout(handle)
      }
    }
  }, [syncState])

  // send data when entering sync
  useEffect(() => {
    if (syncState.state === "sending") {
      let onFinished = (newState: { state: SyncingState }) => setSyncState(newState)
      syncFn()
        .then((status) => {
            if (status === "error") {
              onFinished({ state: "failed" })
              return
            }

            onFinished({ state: "synced" })
        })

      return () => {
        onFinished = () => null
      }
    }
  }, [syncState])

  useEffect(() => {
    console.log(syncState.state)
  }, [syncState])

  return syncState.state
}
